import { AuthTicket } from '@gms/security-api';
import { MfaChallengeInfo, RolesPage, User } from '@gms/user-api';
import { Action } from '@ngrx/store';
import { AuthResources } from './model/models';

export enum EAuthActions {
  LOG_IN = '[AUTH] LogIn',
  LOG_IN_SUCCESS = '[AUTH] LogIn Success',
  LOG_IN_FAILURE = '[AUTH] LogIn Failure',
  FETCH_USER_SUCCESS = '[AUTH] Fetch User Success',
  FETCH_USER_FAILURE = '[AUTH] Fetch User Failure',
  REFRESH = '[AUTH] Refresh',
  REFRESH_SUCCESS = '[AUTH] Refresh Success',
  REFRESH_FAILURE = '[AUTH] Refresh Failure',
  REFRESH_CANCEL = '[AUTH] Refresh Cancel',
  REFRESH_LOGOUT_TIME = '[AUTH] Refresh Logout Time',
  LOG_OUT = '[AUTH] LogOut',
  LOG_OUT_WITH_ERROR = '[AUTH] LogOutWithError',
  LOCK = '[AUTH] Lock',
  FORGET_USER_ID = '[AUTH] Forget userId',
  ACCEPT_COOKIES = '[AUTH] Accept Cookies',
  UPDATE_PASSWORD = '[AUTH] Update Password',
  UPDATE_PASSWORD_SUCCESS = '[AUTH] Update Password Success',
  UPDATE_PASSWORD_FAILURE = '[AUTH] Update Password Failure',
  FETCH_ROUTE_CONFIGS = '[AUTH] Fetch Route Configs',
  FETCH_ROUTE_CONFIGS_SUCCESS = '[AUTH] Fetch Route Configs Success',
  FETCH_ROUTE_CONFIGS_FAILURE = '[AUTH] Fetch Route Configs Failure',
  SSO_LOG_IN = '[AUTH] SsoLogIn',
  SSO_LOG_IN_FAILURE = '[AUTH] SsoLogInFailure',
  SSO_REDIRECT = '[AUTH] SsoRedirect',
  INVALID_SSO_USER_ID = '[AUTH] InvalidSsoUserId',
  UNAUTHORIZED_USER = '[AUTH] UnauthorizedUser',
  LOG_IN_MFA = '[AUTH] LogIn MFA',
  LOG_IN_MFA_ERROR = '[AUTH] LogIn MFA Error',
  REDIRECT_TO_MFA = '[AUTH] Redirect to MFA',
  FETCH_WEBSOCKET_TICKET = '[AUTH] Fetch Websocket Ticket',
  FETCH_WEBSOCKET_TICKET_SUCCESS = '[AUTH] Fetch Websocket Ticket Success',
  FETCH_WEBSOCKET_TICKET_FAILURE = '[AUTH] Fetch Websocket Ticket Failure',
}

export class LogIn implements Action {
  public readonly type = EAuthActions.LOG_IN;

  constructor(public payload: { userId: string; password: string; saveUserId: boolean;  isBisa?: boolean }) { }
}

export class LogInSuccess implements Action {
  public readonly type = EAuthActions.LOG_IN_SUCCESS;

  constructor(
    public payload: {
      userId: string;
      accessToken: string;
      isADFSLogin: boolean;
      refreshToken: string;
      ttl: number;
      roles: string[];
      authResources: AuthResources;
      isInternal: boolean;
      isBisa?: boolean;
    }
  ) {}
}

export class LogInFailure implements Action {
  public readonly type = EAuthActions.LOG_IN_FAILURE;

  constructor(public payload: { error: Error }) {}
}

export class FetchUserSuccess implements Action {
  public readonly type = EAuthActions.FETCH_USER_SUCCESS;

  constructor(public payload: { user: User; redirectToHome: boolean, redirectToBisa?: boolean }) { }
}

export class FetchUserFailure implements Action {
  public readonly type = EAuthActions.FETCH_USER_FAILURE;

  constructor(public payload: { error: Error }) {}
}

export class Refresh implements Action {
  public readonly type = EAuthActions.REFRESH;
}

export class RefreshSuccess implements Action {
  public readonly type = EAuthActions.REFRESH_SUCCESS;

  constructor(
    public payload: {
      accessToken: string;
      ttl: number;
    }
  ) { }
}

export class RefreshFailure implements Action {
  public readonly type = EAuthActions.REFRESH_FAILURE;

  constructor(public payload: { error: Error }) { }
}

export class RefreshCancel implements Action {
  public readonly type = EAuthActions.REFRESH_CANCEL;
}

export class RefreshLogoutTime implements Action {
  public readonly type = EAuthActions.REFRESH_LOGOUT_TIME;
}

export class LogOut implements Action {
  public readonly type = EAuthActions.LOG_OUT;

  constructor(public payload: string = "") { }

}
export class LogOutWithError implements Action {
  public readonly type = EAuthActions.LOG_OUT_WITH_ERROR;

  constructor(public payload: { error: Error }) {}
}

export class Lock implements Action {
  public readonly type = EAuthActions.LOCK;
}

export class ForgetUserId implements Action {
  public readonly type = EAuthActions.FORGET_USER_ID;
}

export class AcceptCookies implements Action {
  public readonly type = EAuthActions.ACCEPT_COOKIES;
}

export class UpdatePassword implements Action {
  public readonly type = EAuthActions.UPDATE_PASSWORD;

  constructor(public payload: { username: string; password: string; confirmationCode: string }) {}
}

export class UpdatePasswordSuccess implements Action {
  public readonly type = EAuthActions.UPDATE_PASSWORD_SUCCESS;
}

export class UpdatePasswordFailure implements Action {
  public readonly type = EAuthActions.UPDATE_PASSWORD_FAILURE;

  constructor(public payload: { error: Error }) {}
}

export class FetchRouteConfigs implements Action {
  public readonly type = EAuthActions.FETCH_ROUTE_CONFIGS;
}

export class FetchRouteConfigsSuccess implements Action {
  public readonly type = EAuthActions.FETCH_ROUTE_CONFIGS_SUCCESS;

  constructor(public payload: { rolesPages: RolesPage[] }) {}
}

export class FetchRouteConfigsFailure implements Action {
  public readonly type = EAuthActions.FETCH_ROUTE_CONFIGS_FAILURE;

  constructor(public payload: { error: Error }) {}
}

export class SsoLogIn implements Action {
  public readonly type = EAuthActions.SSO_LOG_IN;

  constructor(public payload: { code: string, isbisa?: boolean }) { }
}

export class SsoLogInFailure implements Action {
  public readonly type = EAuthActions.SSO_LOG_IN_FAILURE;

  constructor(public payload: { error: Error }) {}
}

export class SsoRedirect implements Action {
  public readonly type = EAuthActions.SSO_REDIRECT;

  constructor(
    public payload: {
      saveUserId: boolean;
      userId: string;
    }
  ) {}
}

export class InvalidSsoUserId implements Action {
  public readonly type = EAuthActions.INVALID_SSO_USER_ID;

  constructor(public payload: { userId: string }) {}
}

export class UnauthorizedUser implements Action {
  public readonly type = EAuthActions.UNAUTHORIZED_USER;
}

export class LogInMfa implements Action {
  public readonly type = EAuthActions.LOG_IN_MFA;

  constructor(
    public payload: {
      userId: string;
      mfaCode: string;
      sessionId: string;
      isBisa?: boolean;
    }
  ) {}
}

export class LogInMfaError implements Action {
  public readonly type = EAuthActions.LOG_IN_MFA_ERROR;

  constructor(public payload: { error: Error }) {}
}

export class RedirectToMfa implements Action {
  public readonly type = EAuthActions.REDIRECT_TO_MFA;

  constructor(
    public payload: {
      mfaChallengeInfo: MfaChallengeInfo;
      userId: string;
      isBisa?: boolean;
    }
  ) {}
}

export class FetchWebsocketTicket implements Action {
  public readonly type = EAuthActions.FETCH_WEBSOCKET_TICKET;

  constructor(public payload: { accessToken: string }) {}
}

export class FetchWebsocketTicketSuccess implements Action {
  public readonly type = EAuthActions.FETCH_WEBSOCKET_TICKET_SUCCESS;

  constructor(public payload: { ticket: AuthTicket }) {}
}

export class FetchWebsocketTicketFailure implements Action {
  public readonly type = EAuthActions.FETCH_WEBSOCKET_TICKET_FAILURE;

  constructor(public payload: { error: Error }) {}
}

export type AuthActions =
  | LogIn
  | LogInSuccess
  | LogInFailure
  | FetchUserSuccess
  | FetchUserFailure
  | Refresh
  | RefreshSuccess
  | RefreshFailure
  | RefreshCancel
  | RefreshLogoutTime
  | LogOut
  | LogOutWithError
  | ForgetUserId
  | AcceptCookies
  | UpdatePassword
  | UpdatePasswordSuccess
  | UpdatePasswordFailure
  | FetchRouteConfigs
  | FetchRouteConfigsSuccess
  | FetchRouteConfigsFailure
  | SsoLogIn
  | SsoLogInFailure
  | SsoRedirect
  | InvalidSsoUserId
  | UnauthorizedUser
  | LogInMfa
  | LogInMfaError
  | RedirectToMfa
  | FetchWebsocketTicket
  | FetchWebsocketTicketSuccess
  | FetchWebsocketTicketFailure;
