import { AbstractControl } from '@angular/forms';
import { IUploadPostingPayload } from 'app/store/posting/posting.actions';
import { formatDate } from 'shared/utils/formatDate.util';
import { isNullOrUndefined } from 'shared/utils/type.utils';
import { LABELS } from 'app/modules/administration/pages/posting/label';

export const getControlsErrors = (controls: { [key: string]: AbstractControl }) => {
  const errors = Object.values(controls)
    .map(control => control.errors)
    .reduce((acc, curr) => {
      return {
        ...acc,
        ...curr,
      };
    }, {});
  return Object.keys(errors);
};

export const removeNullValues = (obj: any) => {
  const newObj = Object.keys(obj).reduce((acc, key) => {
    if (obj[key] !== null && obj[key] !== undefined) { // Fixed condition
      acc[key] = obj[key];
    }
    return acc;
  }, {} as { [key: string]: any });

  return newObj;
};

export const buildCreatePostingPayload = (postingFromValue: any, userId: number, isUploaded: boolean): IUploadPostingPayload => ({
  tspId: postingFromValue.tspId,
  description: postingFromValue.description,
  menuItem: postingFromValue.menuId,
  active: postingFromValue.active,
  datetimePostingEffective: getDateTimeConcatenated(postingFromValue.datePostingEffective, postingFromValue.timePostingEffective),
  datetimePostingExpire: getDateTimeConcatenated(postingFromValue.datePostingExpire, postingFromValue.timePostingExpire),
  document: getDocumentInfo(postingFromValue.document, isUploaded),
  alternateDocument: postingFromValue.alternateDocument != null ? postingFromValue.alternateDocument[0] : null,
  contractId: postingFromValue.contractId != null ? postingFromValue.contractId?.contractNumber : "",
  postImmediately: postingFromValue.postImmediately,
  displayOrder: postingFromValue.displayOrder==="undefined"?0:postingFromValue.displayOrder,
  updateDateTimePostingEffective: postingFromValue.updateDateTimePostingEffective,
  userId: userId,
  isLargeFileUploaded: isUploaded
});

export const getDateTimeConcatenated = (date: Date, time: Date): string => {
  let datetime = '';
  if (!isNullOrUndefined(date)) {
    datetime = `${date.toLocaleDateString()} ${isNullOrUndefined(time) ? '' : time.toTimeString().split(' ')[0]}`;
    if (!isNullOrUndefined(time))
      datetime = formatDate(datetime, PostingConstants.DATE_FORMAT);
  }
  return datetime;
}
export enum PostingConstants {
  DATE_FORMAT = 'MM/dd/yyyy H:mm:ss',
  UPDATE_DATETIME_POSTING_EFFECTIVE = 'updateDateTimePostingEffective',
  DATE_POSTING_EFFECTIVE = 'datePostingEffective',
  TIME_POSTING_EFFECTIVE = 'timePostingEffective',
  DATE_POSTING_EXPIRE = 'datePostingExpire',
  TIME_POSTING_EXPIRE = 'timePostingExpire',
  POST_IMMEDIATELY = 'postImmediately',
  POSTING_DOCUMENT_STATUS_CODE = 'A',
  DOCUMENT_EXTENTION_TYPE_ANY = 'any',
  DOCUMENT_FILE_SIZE_FIVE = 4.5,
  TSPID = 23,
  INFOPOST = 'INFOPOST', //'INFP'
  BISA = 'BISA',
  BISA_TSPID=9
}
export enum PostingsFormErrors {
  PAST_DATE = 'pastDate',
  POSTING_EXPIRE = 'postingExpiry',
  POSTING_TIME_EXPIRE = 'postingTimeExpiry',
  POSTING_TIME_EFFECTIVE = 'postingEffective',
  PAST_POSTING_EXPIRE = 'pastPostingExpiry',
  PAST_POSTING_TIME_EXPIRE = 'pastPostingTimeExpiry',
}
export const PostingsFormErrorMessages = {
  [PostingsFormErrors.PAST_DATE]: LABELS.PAST_EFFECTIVE_DATE,
  [PostingsFormErrors.POSTING_EXPIRE]: LABELS.POSTING_DATE_EXPIRE,
  [PostingsFormErrors.POSTING_TIME_EXPIRE]: LABELS.POSTING_TIME_EXPIRE,
  [PostingsFormErrors.POSTING_TIME_EFFECTIVE]: LABELS.POSTING_TIME_EFFECTIVE,
  [PostingsFormErrors.PAST_POSTING_EXPIRE]: LABELS.PAST_POSTING_EXPIRE,
  [PostingsFormErrors.PAST_POSTING_TIME_EXPIRE]: LABELS.PAST_POSTING_TIME_EXPIRE,

};

export interface Contract {
  contractId: number;
  contractNumber: string;
}


function byteArray(fileName: string): File {
  const text = "";
  const blob = new Blob([text], { type: 'text/plain' });
  const emptyPdfFile = new File([blob], fileName, { type: 'application/pdf' });
  return emptyPdfFile;
}

export function getFileSizeInMB(file: File): number {
  return file.size / (1024 * 1024); // Convert bytes to MB
}

function getDocumentInfo(document: File[], isUploaded: boolean) {
  if (!document)
    return null;
  else if (isUploaded && document)
    return byteArray(document[0].name);
  else {
    return document[0];
  }
}

export interface InfoPostMenuRequest {
  tspID: number;
  group_code: string;
}

export const postingAclResources = {
postingCreate : '/informational-postings/posting',

}